<template>
  <div
    class="tab-pane fade"
    id="int-setting"
    role="tabpanel"
    aria-labelledby="int-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Analiz & Takip</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <!-- Google Analytic ID -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="gan-id" class="custom-label">Google Analytics ID</label>
            <textarea
              id="gan-id"
              class="form-control"
              v-model="data.google_analytic_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Google Analytic ID -->
        <!-- Google Tag Manager ID -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label for="tag-manager" class="custom-label"
                  >Google Tag Manager ID</label
                >
              </div>
            </div>
            <textarea
              id="tag-manager"
              class="form-control"
              v-model="data.google_tag_manager_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Google Tag Manager ID -->

        <!-- Google Merchant -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="google-merchant-center-content" class="custom-label"
              >Google Merchant Center</label
            >
            <textarea
              id="google-merchant-center-content"
              class="form-control"
              v-model="data.google_merchant_center_content"
            ></textarea>
          </div>
        </div>
        <!-- ./Google Merchant -->

        <!-- Google Optimize -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="google-optimize" class="custom-label"
              >Google Optimize Tracking ID</label
            >
            <textarea
              id="google-optimize"
              class="form-control"
              v-model="data.google_optimize_tracking_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Google Optimize -->

        <!-- Search Console -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="google-search-console" class="custom-label"
              >Google Search Console</label
            >
            <textarea
              id="google-search-console"
              class="form-control"
              v-model="data.google_search_console"
            ></textarea>
          </div>
        </div>
        <!-- ./Search Console -->

        <!-- Verification -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="google-site-verification" class="custom-label"
              >Google Site Doğrulama</label
            >
            <textarea
              id="google-site-verification"
              class="form-control"
              v-model="data.google_site_verification"
            ></textarea>
          </div>
        </div>
        <!-- ./Verification -->

        <!-- Google Recaptcha -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="google-recaptcha" class="custom-label"
              >Google Recaptcha - Site Key</label
            >
            <textarea
              id="google-recaptcha"
              class="form-control"
              v-model="data.recaptcha_site_key"
            ></textarea>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="google-recaptcha" class="custom-label"
              >Google Recaptcha - Secret Key</label
            >
            <textarea
              id="google-recaptcha"
              class="form-control"
              v-model="data.recaptcha_secret_key"
            ></textarea>
          </div>
        </div>
        <!-- ./Google Recaptcha -->

        <!-- Google Conversion -->
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-end justify-content-end">
            <div class="d-flex align-items-stop">
              <label class="mr-2">Geliştirilmiş Dönüşümler</label>
              <WeSwitch v-model="data.google_tag_manager_enhanced" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <label for="google-site-verification" class="custom-label"
                >Google Conversion ID</label
              >
              <div class="form-group">
                <textarea
                  id="google-site-verification"
                  class="form-control"
                  v-model="data.google_conversion_id"
                ></textarea>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <label for="google-site-verification" class="custom-label"
                >Google Event ID</label
              >
              <div class="form-group">
                <textarea
                  id="google-site-verification"
                  class="form-control"
                  v-model="data.google_conversion_event_id"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Google Conversion -->

        <!-- Yandex Metrica -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="yandex-metrica" class="custom-label"
              >Yandex Metrica ID</label
            >
            <textarea
              id="yandex-metrica"
              class="form-control"
              v-model="data.yandex_metrica_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Yandex Metrica -->

        <!-- Facebook Pixel -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="facebook-pixel" class="custom-label"
              >Facebook Pixel ID
            </label>
            <textarea
              id="facebook-pixel"
              class="form-control"
              v-model="data.facebook_pixel_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Facebook Pixel -->

        <!-- Facebook Domain Verification -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="facebook-pixel" class="custom-label"
              >Facebook Domain Doğrulama
            </label>
            <textarea
              id="facebook-pixel"
              class="form-control"
              v-model="data.facebook_domain_verification"
            ></textarea>
          </div>
        </div>
        <!-- ./Facebook Domain Verification -->

        <!-- Criteo Account ID -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="criteo-acc-id" class="custom-label"
              >Criteo Hesap ID
            </label>
            <textarea
              id="criteo-acc-id"
              class="form-control"
              v-model="data.criteo_account_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Criteo Account ID -->

        <!-- Microsoft Clarity Project ID -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="criteo-acc-id" class="custom-label"
              >Microsoft Clarity ID
            </label>
            <textarea
              id="criteo-acc-id"
              class="form-control"
              v-model="data.clarity_project_id"
            ></textarea>
          </div>
        </div>
        <!-- ./Microsoft Clarity Project ID -->
      </div>
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "Analyze",
  data() {
    return {
      data: {
        google_analytic_id: this.settings["int.google_analytic_id"],
        google_tag_manager_id: this.settings["int.google_tag_manager_id"],
        google_tag_manager_enhanced:
          this.settings["int.google_tag_manager_enhanced"] == "1" ? 1 : 0,
        google_merchant_center_content:
          this.settings["int.google_merchant_center_content"],
        google_optimize_tracking_id:
          this.settings["int.google_optimize_tracking_id"],
        google_search_console: this.settings["int.google_search_console"],
        google_site_verification: this.settings["int.google_site_verification"],
        google_conversion_id: this.settings["int.google_conversion_id"],
         google_conversion_event_id: this.settings["int.google_conversion_event_id"],
        recaptcha_site_key: this.settings["site.recaptcha_site_key"],
        recaptcha_secret_key: this.settings["site.recaptcha_secret_key"],
        yandex_metrica_id: this.settings["int.yandex_metrica_id"],
        facebook_pixel_id: this.settings["int.facebook_pixel_id"],
        facebook_domain_verification:
          this.settings["int.facebook_domain_verification"],
        criteo_account_id: this.settings["int.criteo_account_id"],
        clarity_project_id: this.settings["int.clarity_project_id"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSave() {
      this.settings["int.google_analytic_id"] = this.data.google_analytic_id;
      this.settings["int.google_tag_manager_id"] =
        this.data.google_tag_manager_id;
      this.settings["int.google_tag_manager_enhanced"] = this.data
        .google_tag_manager_enhanced
        ? 1
        : 0;
      this.settings["int.google_merchant_center_content"] =
        this.data.google_merchant_center_content;
      this.settings["int.google_optimize_tracking_id"] =
        this.data.google_optimize_tracking_id;
      this.settings["int.google_search_console"] =
        this.data.google_search_console;
      this.settings["int.google_site_verification"] =
        this.data.google_site_verification;
      this.settings["int.google_conversion_id"] =
        this.data.google_conversion_id;
         this.settings["int.google_conversion_event_id"] =
        this.data.google_conversion_event_id;
      this.settings["site.recaptcha_site_key"] = this.data.recaptcha_site_key;
      this.settings["site.recaptcha_secret_key"] =
        this.data.recaptcha_secret_key;
      this.settings["int.yandex_metrica_id"] = this.data.yandex_metrica_id;
      this.settings["int.facebook_pixel_id"] = this.data.facebook_pixel_id;
      this.settings["int.facebook_domain_verification"] =
        this.data.facebook_domain_verification;
      this.settings["int.criteo_account_id"] = this.data.criteo_account_id;
      this.settings["int.clarity_project_id"] = this.data.clarity_project_id;
      this.$parent.$emit("on-save", this.settings);
    },
  },
};
</script>
